//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";
import {parser} from "@/utils/parser";

export default {
  name: "SectionCategoryCollectionsEvents",
  components: {
    IntersectionComponent: () => import("@/components/common/IntersectionComponent/IntersectionComponent"),
    EmptyCategoryCollectionSlider: () => import("@/components/common/EmptyCategoryCollectionSlider"),
    CategoryCollectionSquareEventCard: () => import("@/components/common/CategoryCollectionSquareEventCard"),
    Scroller: () => import("@/components/common/Scroller"),
    SliderWithBullets: () => import("@/components/common/SliderWithBullets"),
    Section: () => import("@/components/common/Section")
  },
  data() {
    return {
      events: [],
      pending: true,
      showContentAfterIntersection: false,
    };
  },
  computed: {
    ...mapGetters({
      city: 'cities/default'
    }),
    gap() {
      return this.$mediaSwitch({hd: 32, sm: 8, defaultValue: 24})
    },
    columns() {
      return this.$mediaSwitch({hd: 4, md: 2, sm: 1, defaultValue: 3})
    }
  },
  methods: {
    intersected() {
      this.showContentAfterIntersection = true;
      this.$axios.$get(`/api/category-collections/events?${parser({
        params: {
          lang: this.$i18n.locale,
          city_id: this.$config.singleCity ? this.city.id : null,
          count: 100500,
          resolution: 'micro'
        }
      })}`)
        .then(response => {
          this.events = response.data || [];
        })
        .catch(() => null)
        .finally(() => this.pending = false);
    },
  }
};
