import { render, staticRenderFns } from "./index.vue?vue&type=template&id=912cf2c0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoryCollectionSquareEventCard: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/CategoryCollectionSquareEventCard/index.vue').default,SliderWithBullets: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/SliderWithBullets/index.vue').default,Scroller: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Scroller/index.vue').default,EmptyCategoryCollectionSlider: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/EmptyCategoryCollectionSlider/index.vue').default,IntersectionComponent: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/IntersectionComponent/IntersectionComponent.vue').default,Section: require('/var/www/tourism-front.demo.rusatom.dev/releases/56/components/common/Section/index.vue').default})
