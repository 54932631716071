//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CategoryCollectionSquareEventCard",
  components: {
    ImageComponent: () => import("@/components/common/ImageComponent")
  },
  props: {
    slug: {
      type: String,
      default: () => ""
    },
    image: {
      type: String,
      default: () => ""
    },
    title: {
      type: String,
      default: () => ""
    }
  }
};
